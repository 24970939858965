<template>
  <v-menu offset-y>
    <template v-slot:activator="{ on, attrs }">
      <v-btn color="primary" dark v-bind="attrs" v-on="on" icon>
        <v-icon>mdi-dots-vertical</v-icon>
      </v-btn>
    </template>
    <v-list>
      <v-list-item @click="() => {}">
        <v-list-item-action class="mr-2">
          <v-checkbox v-model="isVisibleValue"></v-checkbox>
        </v-list-item-action>
        <v-list-item-content>
          <v-list-item-title>
            {{ $t("forms.builder.menu.hide_field.title") }}
          </v-list-item-title>
        </v-list-item-content>
      </v-list-item>
      <v-list-item @click="() => {}">
        <v-list-item-action class="mr-2">
          <v-checkbox v-model="readOnlyValue"></v-checkbox>
        </v-list-item-action>
        <v-list-item-content>
          <v-list-item-title>
            {{ $t("forms.builder.menu.read_only_field.title") }}
          </v-list-item-title>
        </v-list-item-content>
      </v-list-item>
    </v-list>
  </v-menu>
</template>

<script>
export default {
  props: {
    isVisible: {
      type: Boolean,
      default: false,
    },
    readOnly: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({}),
  computed: {
    isVisibleValue: {
      get() {
        return !this.isVisible;
      },
      set(val) {
        return this.$emit("update:isVisible", !val);
      },
    },
    readOnlyValue: {
      get() {
        return this.readOnly;
      },
      set(val) {
        return this.$emit("update:readOnly", val);
      },
    },
  },
};
</script>