var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"builder"},[_c('v-form',{ref:"builder",model:{value:(_vm.validValue),callback:function ($$v) {_vm.validValue=$$v},expression:"validValue"}},[_c('draggable',{attrs:{"tag":"div","handle":".draggable-handle","direction":"vertical","force-fallback":true},on:{"start":function($event){_vm.isDragging = true},"end":function($event){_vm.isDragging = false},"change":_vm.updateDraggable},model:{value:(_vm.builder),callback:function ($$v) {_vm.builder=$$v},expression:"builder"}},[_c('transition-group',{attrs:{"type":"transition","tag":"div","duration":"500","name":"fade-transition"}},_vm._l((_vm.builder),function(field,index){return _c('v-hover',{key:("field-" + index),scopedSlots:_vm._u([{key:"default",fn:function(ref){
var hover = ref.hover;
return [_c('div',{on:{"click":function($event){return _vm.selectField(index)}}},[_c('field',{ref:("field_" + index),refInFor:true,attrs:{"selected":_vm.selected === index,"options":field,"field-items":_vm.fieldItems},scopedSlots:_vm._u([{key:"field-info",fn:function(){return [_c('v-list',{staticClass:"rounded-lg elevation-2",attrs:{"subheader":"","two-line":""}},[_c('field-draggable-handle',{attrs:{"hover":hover}}),_c('v-list-item',{staticClass:"pt-0 mt-0"},[_c('v-list-item-content',{staticClass:"pt-0"},[_c('v-list-item-title',[_c('span',{staticClass:"text-h6"},[_vm._v(_vm._s(field.title))])]),_c('v-list-item-subtitle',[_c('span',{staticClass:"grey--text text--darken-2"},[_vm._v(_vm._s(field.name))])])],1),_c('v-list-item-action',[_c('field-icon',{attrs:{"type":field.type}})],1)],1)],1)]},proxy:true},{key:"draggable-handler",fn:function(){return [_vm._t("draggable-handler",function(){return [_c('field-draggable-handle',{attrs:{"hover":hover}})]})]},proxy:true},{key:"types",fn:function(){return [_c('field-type',{attrs:{"type":field.type},on:{"update:type":function($event){return _vm.$set(field, "type", $event)},"changed":function (type) { return _vm.changeField(index, type); }}})]},proxy:true},{key:"footer-controls",fn:function(){return [_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":""},on:{"click":function($event){return _vm.duplicateField(index)}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"color":"grey darken-2"}},[_vm._v("mdi-content-copy")])],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t("btn.duplicate")))])]),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"ml-2",attrs:{"icon":""},on:{"click":function($event){return _vm.removeField(index)}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"color":"grey darken-2","size":"24"}},[_vm._v("mdi-delete-outline")])],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t("btn.delete")))])]),_c('v-divider',{staticClass:"mx-3",attrs:{"vertical":""}}),_c('v-switch',{staticClass:"mr-3",attrs:{"label":_vm.$t('common.required'),"color":"primary"},model:{value:(field.isRequired),callback:function ($$v) {_vm.$set(field, "isRequired", $$v)},expression:"field.isRequired"}}),_c('field-menu',{attrs:{"is-visible":field.isVisible,"read-only":field.readOnly},on:{"update:isVisible":function($event){return _vm.$set(field, "isVisible", $event)},"update:is-visible":function($event){return _vm.$set(field, "isVisible", $event)},"update:readOnly":function($event){return _vm.$set(field, "readOnly", $event)},"update:read-only":function($event){return _vm.$set(field, "readOnly", $event)}}})]},proxy:true},(index > 0)?{key:"conditions",fn:function(){return [_c('field-condition',{attrs:{"field-items":_vm.fieldItems.filter(function (f) { return f.value !== field.name; }),"conditions":field.visibleIf},on:{"update:conditions":function($event){return _vm.$set(field, "visibleIf", $event)}}})]},proxy:true}:null],null,true)})],1)]}}],null,true)})}),1)],1)],1),_c('v-fab-transition',[_c('v-tooltip',{attrs:{"left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"mb-5",staticStyle:{"right":"50px","bottom":"20px"},attrs:{"fab":"","bottom":"","right":"","fixed":""},on:{"click":function($event){return _vm.addField()}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-plus-circle-outline")])],1)]}}])},[_c('span',[_vm._v(_vm._s(_vm.$t("forms.btn.add_field.title")))])])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }