<template>
  <div>
    <div class="font-weight-medium mb-2 text-body-2">
      {{ $t("forms.events.fields.email.subject.title") }}
    </div>
    <fields-name-button
      :forms-field-names="formsFieldNames"
      @insertField="
        (insertText) =>
          insertField(insertText, $refs.subject.$refs.input, 'subject')
      "
      class="mb-2"
    ></fields-name-button>
    <v-text-field
      :placeholder="$t('forms.events.fields.email.subject.placeholder')"
      v-model="form.subject"
      class="mb-3"
      @input="delayTouch($v.form.subject)"
      @blur="$v.form.subject.$touch"
      :error-messages="subjectErrors"
      maxlength="255"
      ref="subject"
      outlined
      dense
    ></v-text-field>
    <v-text-field
      :label="$t('forms.events.fields.email.to.title')"
      :hint="$t('forms.events.fields.email.to.help')"
      v-model="form.to"
      class="mb-6"
      @input="delayTouch($v.form.to)"
      @blur="$v.form.to.$touch"
      :error-messages="toErrors"
      persistent-hint
    ></v-text-field>
    <v-text-field
      :label="$t('forms.events.fields.email.cc.title')"
      :hint="$t('forms.events.fields.email.cc.help')"
      v-model="form.cc"
      class="mb-5 mt-2"
      @input="delayTouch($v.form.cc)"
      @blur="$v.form.cc.$touch"
      :error-messages="ccErrors"
      persistent-hint
    ></v-text-field>
    <v-text-field
      :label="$t('forms.events.fields.email.bcc.title')"
      :hint="$t('forms.events.fields.email.bcc.help')"
      @input="delayTouch($v.form.bcc)"
      @blur="$v.form.bcc.$touch()"
      :error-messages="bccErrors"
      persistent-hint
      v-model="form.bcc"
      class="mb-7"
    ></v-text-field>
    <div class="font-weight-medium mb-2 text-body-2">
      {{ $t("forms.events.fields.email.body.title") }}
    </div>
    <fields-name-button
      :forms-field-names="formsFieldNames"
      @insertField="
        (insertText) =>
          insertField(insertText, $refs.template.$refs.input, 'template')
      "
      class="mb-2"
    ></fields-name-button>
    <v-textarea
      v-model="form.template"
      :label="$t('forms.events.fields.email.body.placeholder')"
      class="mb-3"
      no-resize
      rows="5"
      maxlength="300000"
      @input="delayTouch($v.form.template)"
      @blur="$v.form.template.$touch()"
      :error-messages="bodyErrors"
      outlined
      ref="template"
    ></v-textarea>
    <div class="mb-2 text-body-2">
      <span class="font-weight-medium">{{
        $t("forms.events.fields.email.code.title")
      }}</span>
    </div>
    <fields-name-button
      :forms-field-names="formsFieldNames"
      class="mb-2"
      @insertField="
        (insertText) =>
          insertField(insertText, $refs.code.$refs.textarea, 'programmation')
      "
    ></fields-name-button>
    <prism-editor
      class="app-prism-editor"
      v-model="form.programmation"
      :highlight="highlighter"
      line-numbers
      :placeholder="$t('forms.events.fields.email.code.placeholder')"
      ref="code"
    ></prism-editor>
  </div>
</template>

<script>
import { PrismEditor } from "vue-prism-editor";
import { highlight, languages } from "prismjs/components/prism-core";
import "prismjs/components/prism-clike";
import "prismjs/components/prism-javascript";
import "prismjs/themes/prism-tomorrow.css";
import { required, helpers, maxLength } from "vuelidate/lib/validators";
import FormDelayTouchMixin from "./../../mixins/form-delay-touch";
import Vue from "vue";
import FieldsNameButton from "./fields-name-button";

const defaultForm = {
  subject: null,
  template: null,
  to: null,
  cc: null,
  bcc: null,
  programmation: null,
};

const matchSeparatedEmail = (val) =>
  !helpers.req(val) || Vue.$utils.matchSeparatedEmail(val);
const matchTotalEmail = (val) =>
  !helpers.req(val) || Vue.$utils.getStringToArray(val).length < 31;

export default {
  mixins: [FormDelayTouchMixin],
  created() {
    this.initForm();
  },
  props: {
    event: {
      type: Object,
      default: function () {
        return {};
      },
    },
    formsFieldNames: {
      type: Object,
      default: function () {
        return {};
      },
    },
  },
  data: () => ({
    form: Object.assign({}, defaultForm),
  }),
  validations: {
    form: {
      subject: { required, maxLength: maxLength(255) },
      template: { required, maxLength: maxLength(300000) },
      programmation: { maxLength: maxLength(30000) },
      to: {
        matchEmail: matchSeparatedEmail,
        matchTotalEmail: matchTotalEmail,
      },
      cc: {
        matchEmail: matchSeparatedEmail,
        matchTotalEmail: matchTotalEmail,
      },
      bcc: {
        matchEmail: matchSeparatedEmail,
        matchTotalEmail: matchTotalEmail,
      },
    },
  },
  methods: {
    highlighter(code) {
      return highlight(code, languages.js);
    },
    initForm() {
      if (this.$utils.getPropertyValue(this.event, "_id")) {
        this.form.subject = this.event.data["subject"];
        this.form.template = this.event.data["template"];
        this.form.to = this.event.data["to"].join(",");
        this.form.cc = this.event.data["cc"].join(",");
        this.form.bcc = this.event.data["bcc"].join(",");
        this.form.programmation = this.event.data["programmation"];
      } else {
        this.$set(this, "form", Object.assign({}, defaultForm));
      }
    },
    getEventData() {
      const { to, cc, bcc, ...rest } = this.form;
      return {
        ...rest,
        to: to ? to.split(",") : [],
        cc: cc ? cc.split(",") : [],
        bcc: bcc ? bcc.split(",") : [],
      };
    },
    getValidation() {
      this.$v.form.$touch();
      return !this.$v.form.$invalid;
    },
    resetForm() {
      this.$v.form.$reset();
      this.$set(this, "form", Object.assign({}, defaultForm));
    },
    insertField(insertText, ref, name) {
      const template = ref;
      const sentence = template.value;
      const len = sentence.length;
      let pos = template.selectionStart;

      if (pos === undefined) {
        pos = 0;
      }

      const before = sentence.substr(0, pos);
      const after = sentence.substr(pos, len);

      this.form[name] = before + insertText + after;
      this.$nextTick().then(() => {
        template.selectionStart = pos + insertText.length;
        template.focus();
      });
    },
  },
  computed: {
    toErrors() {
      const errors = [];

      if (!this.$v.form.to.$dirty) return errors;
      !this.$v.form.to.matchEmail &&
        errors.push(this.$t("forms.events.fields.email.to.invalid"));
      !this.$v.form.to.matchTotalEmail &&
        errors.push(this.$t("forms.events.fields.email.to.invalidTotal"));
      return errors;
    },
    ccErrors() {
      const errors = [];

      if (!this.$v.form.cc.$dirty) return errors;
      !this.$v.form.cc.matchEmail &&
        errors.push(this.$t("forms.events.fields.email.cc.invalid"));
      !this.$v.form.cc.matchTotalEmail &&
        errors.push(this.$t("forms.events.fields.email.cc.invalidTotal"));
      return errors;
    },
    bccErrors() {
      const errors = [];

      if (!this.$v.form.bcc.$dirty) return errors;
      !this.$v.form.bcc.matchEmail &&
        errors.push(this.$t("forms.events.fields.email.bcc.invalid"));
      !this.$v.form.bcc.matchTotalEmail &&
        errors.push(this.$t("forms.events.fields.email.bcc.invalidTotal"));
      return errors;
    },
    subjectErrors() {
      const errors = [];

      if (!this.$v.form.subject.$dirty) return errors;
      !this.$v.form.subject.required &&
        errors.push(this.$t("forms.events.fields.email.subject.required"));
      !this.$v.form.subject.maxLength &&
        errors.push(
          this.$t("forms.events.fields.email.subject.maxLength", {
            max: this.$v.form.subject.$params.maxLength.max,
          })
        );
      return errors;
    },
    bodyErrors() {
      const errors = [];

      if (!this.$v.form.template.$dirty) return errors;
      !this.$v.form.template.required &&
        errors.push(this.$t("forms.events.fields.email.body.required"));
      !this.$v.form.template.maxLength &&
        errors.push(
          this.$t("forms.events.fields.email.body.maxLength", {
            max: this.$v.form.template.$params.maxLength.max,
          })
        );
      return errors;
    },
    codeErrors() {
      const errors = [];

      if (!this.$v.form.programmation.$dirty) return errors;
      !this.$v.form.programmation.maxLength &&
        errors.push(
          this.$t("forms.events.fields.email.code.maxLength", {
            max: this.$v.form.programmation.$params.maxLength.max,
          })
        );
      return errors;
    },
  },
  components: {
    PrismEditor,
    FieldsNameButton,
  },
};
</script>