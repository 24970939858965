<template>
  <v-dialog
    transition="dialog-bottom-transition"
    no-click-animation
    v-model="dialog.display"
    fullscreen
    persistent
  >
    <v-card tile color="#e8eff5">
      <v-app-bar fixed flat color="grey lighten-5" max-height="64">
        <v-btn icon @click="closeDialog()">
          <v-icon>mdi-close</v-icon>
        </v-btn>
        <v-toolbar-title>
          <span class="secondary-font font-weight-medium">
            {{ $t("forms.events.title") }}
          </span>
        </v-toolbar-title>
        <v-spacer></v-spacer>
        <v-toolbar-items>
          <v-btn
            text
            @click="save()"
            :disabled="$v.form.$invalid"
            :loading="loading.save"
            >{{ $t("btn.save") }}</v-btn
          >
        </v-toolbar-items>
      </v-app-bar>
      <v-card-text style="padding-top: 70px">
        <v-row justify="center">
          <v-col md="5" cols="12">
            <v-expansion-panels v-model="panel" multiple>
              <v-expansion-panel class="rounded-lg">
                <v-expansion-panel-header color="grey lighten-4">
                  <span class="font-weight-bold">
                    {{ $t("forms.events.list.title") }}
                  </span>
                </v-expansion-panel-header>
                <v-expansion-panel-content class="pt-3">
                  <v-btn
                    @click="addEventAction()"
                    depressed
                    class="mb-4 mt-2"
                    small
                    v-if="event"
                  >
                    <v-icon left>mdi-calendar-plus</v-icon>
                    {{ $t("btn.add") }}
                  </v-btn>
                  <v-simple-table dense v-if="events.length > 0">
                    <template v-slot:default>
                      <thead>
                        <tr>
                          <th class="text-left px-0">
                            <span
                              class="grey--text text--darken-3 font-weight-bold"
                              >{{ $t("forms.events.fields.name.title") }}</span
                            >
                          </th>
                          <th class="text-right px-0" width="100px"></th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr
                          v-for="(target, index) in events"
                          :key="`target-${index}`"
                        >
                          <td class="text-left px-0 py-2">
                            <span>{{ target.title }}</span>
                          </td>
                          <td class="text-right px-0 p-2">
                            <v-tooltip top>
                              <template v-slot:activator="{ on, attrs }">
                                <v-btn
                                  icon
                                  color="primary"
                                  v-on="on"
                                  v-bind="attrs"
                                  small
                                  @click="editEventAction(target)"
                                  class="mr-1"
                                >
                                  <v-icon small>mdi-pencil-outline</v-icon>
                                </v-btn>
                              </template>
                              <span>{{ $t("btn.edit") }}</span>
                            </v-tooltip>
                            <v-tooltip top>
                              <template v-slot:activator="{ on, attrs }">
                                <v-btn
                                  icon
                                  color="danger"
                                  v-on="on"
                                  v-bind="attrs"
                                  small
                                  @click="deleteEventAction(target)"
                                  class="mr-2"
                                >
                                  <v-icon small>mdi-delete-outline</v-icon>
                                </v-btn>
                              </template>
                              <span>{{ $t("btn.delete") }}</span>
                            </v-tooltip>
                          </td>
                        </tr>
                      </tbody>
                    </template>
                  </v-simple-table>
                </v-expansion-panel-content>
              </v-expansion-panel>
              <v-expansion-panel class="mt-4 rounded-lg" id="event-form">
                <v-expansion-panel-header color="grey lighten-4">
                  <span class="font-weight-bold">
                    <span v-if="event" class="font-weight-bold">
                      {{
                        $t("forms.events.update.title", { name: event.title })
                      }}
                    </span>
                    <span v-else class="font-weight-bold">{{
                      $t("forms.events.create.title")
                    }}</span>
                  </span>
                </v-expansion-panel-header>
                <v-expansion-panel-content>
                  <v-select
                    :items="eventTypes"
                    :label="$t('forms.events.fields.type.title')"
                    v-model="form.type"
                    @input="delayTouch($v.form.type)"
                    @blur="$v.form.type.$touch"
                    :error-messages="eventTypeErrors"
                    class="font-weight-medium mt-4"
                  ></v-select>
                  <v-select
                    :items="eventSignals"
                    :label="$t('forms.events.fields.signal.title')"
                    v-model="form.signal"
                    @input="delayTouch($v.form.signal)"
                    @blur="$v.form.signal.$touch"
                    :error-messages="eventSignalErrors"
                    class="font-weight-medium"
                  ></v-select>
                  <v-text-field
                    :label="$t('forms.events.fields.name.title')"
                    v-model="form.title"
                    @input="delayTouch($v.form.title)"
                    @blur="$v.form.title.$touch"
                    :error-messages="titleErrors"
                    class="font-weight-medium"
                  ></v-text-field>
                  <v-checkbox
                    v-model="form.enabled"
                    color="primary"
                    :label="$t('forms.events.fields.enabled.title')"
                    class="
                      mb-3
                      mt-0
                      text-body-1
                      app-checkbox
                      font-weight-medium
                    "
                    hide-details
                  ></v-checkbox>
                  <template v-if="form.type">
                    <form-event-email
                      ref="eventData"
                      v-if="form.type === 'EMAIL'"
                      :event="event"
                      :forms-field-names="formsFieldNames"
                      class="mt-7"
                    ></form-event-email>
                  </template>
                </v-expansion-panel-content>
              </v-expansion-panel>
            </v-expansion-panels>
          </v-col>
        </v-row>
        <v-row justify="center">
          <v-col md="5" cols="12" class="mt-0 pt-0">
            <div class="d-flex justify-end">
              <v-btn
                @click="save()"
                :disabled="$v.form.$invalid"
                :loading="loading.save"
                color="primary"
                >{{ $t("btn.save") }}</v-btn
              >
            </div>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
    <confirm-event-delete
      @confirm="confirmDeleteEvent"
      :dialog="dialogConfirmDelete"
    ></confirm-event-delete>
  </v-dialog>
</template>

<script>
import ConfirmEventDelete from "./../commons/confirm";
import { required, maxLength, helpers } from "vuelidate/lib/validators";
import FormEventEmail from "./../forms/event-email";
import { mapGetters, mapActions } from "vuex";
import FormDelayTouchMixin from "./../../mixins/form-delay-touch";
import { defaultFormsEvent } from './../../constants';

export default {
  mixins: [FormDelayTouchMixin],
  props: {
    events: {
      type: Array,
      default: function () {
        return [];
      },
    },
    dialog: {
      type: Object,
      default: function () {
        return {
          display: false,
        };
      },
    },
    formsId: {
      type: Number,
    },
  },
  data: () => ({
    event: null,
    eventAtDeleted: null,
    panel: [0, 1],
    form: { ...defaultFormsEvent },
    loading: {
      save: false,
      delete: false,
    },
    dialogConfirmDelete: {
      display: false,
      message: null,
    },
    formsFieldNames: {},
  }),
  methods: {
    addEventAction() {
      if (!this.event) return;
      this.event = null;

      this.$vuetify.goTo("#event-form");
      this.$refs.eventData.resetForm();
      this.$v.form.$reset();
      this.form = Object.assign({}, defaultFormsEvent);
    },
    editEventAction(event) {
      this.event = event;
      this.form = {
        title: event.title,
        type: event.hook,
        enabled: event.enabled,
        signal: event.signal,
      };
    },
    deleteEventAction(event) {
      this.eventAtDeleted = event;
      this.dialogConfirmDelete = {
        display: true,
        message: this.$t("forms.events.delete.title"),
      };
    },
    async confirmDeleteEvent() {
      if (!this.eventAtDeleted) return;

      this.loading.delete = true;
      try {
        this.request({
          url: "forms.deleteEvent",
          method: "POST",
          data: {
            formsId: this.formsId,
            eventId: this.eventAtDeleted._id,
          },
          messages: {
            204: this.$t("forms.events.delete.success"),
            403: true,
            500: true,
          },
        });
        this.postDeleted();
      } catch (error) {
        // empty
      }
      this.loading.delete = false;
    },
    async save() {
      const eventRef = this.$refs.eventData;
      this.notify({ status: false });
      this.$v.form.$touch();

      if (!eventRef) return;
      const eventDataFormIsValid = eventRef.getValidation();

      if (this.$v.form.$invalid || !eventDataFormIsValid) {
        this.notify({ message: this.$t("form_error") });
        return;
      }

      let data = {
        data: eventRef.getEventData(),
        signal: this.form.signal,
        title: this.form.title,
        enabled: this.form.enabled,
        hook: this.form.type,
        formsId: this.formsId,
      };
      let url;
      let success;

      if (this.$utils.hasProperty(this.event, "_id")) {
        url = "forms.updateEvent";
        data.eventId = this.event._id;
        data.formId = this.formsId;
        success = "forms.events.update.success";
      } else {
        url = "forms.addEvent";
        success = "forms.events.create.success";
      }
      this.loading.save = true;
      try {
        const response = await this.request({
          url: url,
          method: "POST",
          data: data,
          messages: {
            200: this.$t(success),
            403: true,
            400: true,
            422: (errors) => {
              if (errors.code === "E101") {
                return this.$t("form_error");
              } else {
                return this.$t("error_occured");
              }
            },
            500: true,
          },
        });
        this.postSave(response.data);
      } catch (error) {
        // empty
      }
      this.loading.save = false;
    },
    postSave(data) {
      this.$emit("eventsUpdated", {
        data: data,
        new: this.isNew,
      });
      // reset form
      if (this.isNew) {
        this.resetForm();
      }
    },
    postDeleted() {
      const id = this.eventAtDeleted._id;
      this.$emit("eventDeleted", id);
      if (this.event && this.event._id === id) {
        this.resetForm();
      }
    },
    resetForm() {
      this.$refs.eventData.resetForm();
      this.$v.form.$reset();
      this.form = Object.assign({}, defaultFormsEvent);
    },
    closeDialog() {
      this.dialog.display = false;
    },
    ...mapActions({ request: "request", notify: "notification/notify" }),
  },
  validations: {
    form: {
      title: { required, maxLength: maxLength(50) },
      type: {
        required,
        matchType: (val) => !helpers.req(val) || /^(EMAIL|SMS)$/.test(val),
      },
      signal: {
        required,
        matchSignal: (val) => !helpers.req(val) || /^(POST|PRE)$/.test(val),
      },
    },
  },
  components: {
    FormEventEmail,
    ConfirmEventDelete,
  },
  computed: {
    ...mapGetters({
      eventTypes: "forms/eventTypes",
      eventSignals: "forms/eventSignals",
    }),
    isNew() {
      return this.event ? false : true;
    },
    eventTypeErrors() {
      const errors = [];

      if (!this.$v.form.type.$dirty) return errors;

      !this.$v.form.type.required &&
        errors.push(this.$t("forms.events.fields.type.required"));
      !this.$v.form.type.matchType &&
        errors.push(this.$t("forms.events.fields.type.invalid"));

      return errors;
    },
    eventSignalErrors() {
      const errors = [];

      if (!this.$v.form.signal.$dirty) return errors;

      !this.$v.form.signal.required &&
        errors.push(this.$t("forms.events.fields.signal.required"));
      !this.$v.form.signal.matchSignal &&
        errors.push(this.$t("forms.events.fields.signal.invalid"));

      return errors;
    },
    titleErrors() {
      const errors = [];
      if (!this.$v.form.title.$dirty) return errors;

      !this.$v.form.title.required &&
        errors.push(this.$t("forms.events.fields.name.required"));
      !this.$v.form.title.maxLength &&
        errors.push(
          this.$t("forms.events.fields.name.maxLength", {
            max: this.$v.form.title.$params.maxLength.max,
          })
        );

      return errors;
    },
  },
  watch: {
    async "dialog.display"(val) {
      if (!val || !this.formsId) return;
      try {
        const response = await this.request({
          url: "forms.fields",
          method: "POST",
          data: {
            formsId: this.formsId,
          },
        });
        this.formsFieldNames = response.data || {};
      } catch (error) {
        // empty
      }
    },
  },
};
</script>