<template>
  <div class="mt-5 ma-auto">
    <div v-if="!selected">
      <slot name="field-info"></slot>
    </div>
    <v-card v-else class="rounded-lg field-selected elevation-4">
      <slot name="draggable-handler"></slot>
      <v-card-text class="pt-0">
        <v-row wrap>
          <v-col cols="12" sm="6">
            <v-text-field
              v-model="options.name"
              dense
              filled
              :rules="rulesName"
            ></v-text-field>
          </v-col>
          <v-col cols="12" sm="6">
            <slot name="types"></slot>
          </v-col>
        </v-row>
        <v-text-field
          dense
          :placeholder="$t('common.fields.title.title')"
          v-model="options.title"
          class="mb-4"
          :rules="rulesTitle"
          ref="title"
        ></v-text-field>
        <v-text-field
          dense
          :placeholder="$t('common.fields.description.title')"
          v-model="options.placeholder"
          :rules="rulesDescription"
        ></v-text-field>
        <v-checkbox
          v-model="options.multiple"
          hide-details
          dense
          class="mb-0 pb-0"
          :label="$t('forms.builder.fields.select.choice.multiple')"
          :disabled="disableMultiple"
        ></v-checkbox>
      </v-card-text>
      <v-card-text class="pt-2">
        <div class="mb-3">
          <span class="text-subtitle-1 font-weight-medium">{{
            $t("forms.builder.fields.select.format.title")
          }}</span>
        </div>
        <v-radio-group v-model="options.format" hide-details row>
          <v-radio
            :label="$t('forms.builder.fields.select.format.list')"
            color="primary"
            value="select"
          ></v-radio>
          <v-radio
            :label="$t('forms.builder.fields.select.format.radio')"
            color="primary"
            value="radio"
          ></v-radio>
          <v-radio
            :label="$t('forms.builder.fields.select.format.checkbox')"
            color="primary"
            value="checkbox"
          ></v-radio>
        </v-radio-group>
      </v-card-text>
      <v-card-text class="pt-2">
        <div class="mb-3">
          <span class="text-subtitle-1 font-weight-medium">{{
            $t("forms.builder.fields.select.choice.title")
          }}</span>
          <v-tooltip top>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                @click="addOption()"
                class="ml-2"
                v-on="on"
                v-bind="attrs"
                icon
                ><v-icon>mdi-plus-circle-outline</v-icon></v-btn
              >
            </template>
            <span>{{ $t("forms.builder.fields.select.choice.title") }}</span>
          </v-tooltip>
        </div>
        <div
          class="d-flex wrap align-center mb-3"
          v-for="(item, index) in options.items"
          :key="index"
        >
          <span class="mr-2"><v-icon>mdi-radiobox-blank</v-icon></span>
          <v-text-field
            :label="$t('common.fields.value.title')"
            v-model="item.value"
            dense
          ></v-text-field>
          <v-text-field
            :label="$t('common.fields.text.title')"
            v-model="item.text"
            class="ml-4"
            dense
          ></v-text-field>
          <v-tooltip top>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                v-on="on"
                v-bind="attrs"
                small
                icon
                class="ml-2"
                @click="removeOption(index)"
                v-if="index > 0"
              >
                <v-icon small>mdi-close</v-icon>
              </v-btn>
            </template>
            <span>{{ $t("btn.delete") }}</span>
          </v-tooltip>
        </div>
      </v-card-text>
      <v-card-text>
        <slot name="conditions"></slot>
      </v-card-text>
      <v-divider></v-divider>
      <v-card-actions class="px-4 py-0">
        <v-spacer></v-spacer>
        <slot name="footer-controls"></slot>
      </v-card-actions>
    </v-card>
  </div>
</template>

<script>
import DefaultRulesMixin from "../mixins/default-rules";

export default {
  mixins: [DefaultRulesMixin],
  data: () => ({
    disableMultiple: false
  }),
  props: {
    options: {
      type: Object,
      default: function () {
        return {};
      },
    },
    selected: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    addOption() {
      const countItems = this.options.items.length;
      this.options.items.push({
        value: `Option${countItems}`,
        text: `Option ${countItems}`,
      });
    },
    removeOption(index) {
      this.options.items.splice(index, 1);
    },
  },
  watch: {
    selected: function (val) {
      if (val) {
        this.$nextTick(() => {
          this.$refs.title.$refs.input.focus();
        });
      }
    },
    "options.format"(val) {
      if(val === 'radio') {
        this.options.multiple = false;
        this.disableMultiple = true;
      }else{
        this.disableMultiple = false;
      }
    }
  },
  components: {},
};
</script>