<template>
  <div class="builder">
    <v-form ref="builder" v-model="validValue">
      <draggable
        tag="div"
        v-model="builder"
        @start="isDragging = true"
        @end="isDragging = false"
        handle=".draggable-handle"
        direction="vertical"
        @change="updateDraggable"
        :force-fallback="true"
      >
        <transition-group
          type="transition"
          tag="div"
          duration="500"
          name="fade-transition"
        >
          <v-hover
            v-slot="{ hover }"
            v-for="(field, index) in builder"
            :key="`field-${index}`"
          >
            <div @click="selectField(index)">
              <field
                :selected="selected === index"
                :ref="`field_${index}`"
                :options="field"
                :field-items="fieldItems"
              >
                <template v-slot:field-info>
                  <v-list
                    subheader
                    two-line
                    class="rounded-lg elevation-2"
                  >
                    <field-draggable-handle
                      :hover="hover"
                    ></field-draggable-handle>
                    <v-list-item class="pt-0 mt-0">
                      <v-list-item-content class="pt-0">
                        <v-list-item-title>
                          <span class="text-h6">{{ field.title }}</span>
                        </v-list-item-title>

                        <v-list-item-subtitle
                          ><span class="grey--text text--darken-2">{{
                            field.name
                          }}</span></v-list-item-subtitle
                        >
                      </v-list-item-content>

                      <v-list-item-action>
                        <field-icon :type="field.type"></field-icon>
                      </v-list-item-action>
                    </v-list-item>
                  </v-list>
                </template>
                <template v-slot:draggable-handler>
                  <slot name="draggable-handler">
                    <field-draggable-handle
                      :hover="hover"
                    ></field-draggable-handle>
                  </slot>
                </template>
                <template v-slot:types>
                  <field-type
                    :type.sync="field.type"
                    @changed="(type) => changeField(index, type)"
                  ></field-type>
                </template>
                <template v-slot:footer-controls>
                  <v-tooltip top>
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        icon
                        @click="duplicateField(index)"
                        v-on="on"
                        v-bind="attrs"
                        ><v-icon color="grey darken-2"
                          >mdi-content-copy</v-icon
                        ></v-btn
                      >
                    </template>
                    <span>{{ $t("btn.duplicate") }}</span>
                  </v-tooltip>
                  <v-tooltip top>
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        class="ml-2"
                        icon
                        @click="removeField(index)"
                        v-on="on"
                        v-bind="attrs"
                        ><v-icon color="grey darken-2" size="24"
                          >mdi-delete-outline</v-icon
                        ></v-btn
                      >
                    </template>
                    <span>{{ $t("btn.delete") }}</span>
                  </v-tooltip>
                  <v-divider vertical class="mx-3"></v-divider>
                  <v-switch
                    v-model="field.isRequired"
                    :label="$t('common.required')"
                    color="primary"
                    class="mr-3"
                  ></v-switch>
                  <field-menu
                    :is-visible.sync="field.isVisible"
                    :read-only.sync="field.readOnly"
                  ></field-menu>
                </template>
                <template v-slot:conditions v-if="index > 0">
                  <field-condition
                    :field-items="fieldItems.filter(f => f.value !== field.name)"
                    :conditions.sync="field.visibleIf"
                  ></field-condition>
                </template>
              </field>
            </div>
          </v-hover>
        </transition-group>
      </draggable>
    </v-form>

    <v-fab-transition>
      <v-tooltip left>
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            fab
            bottom
            right
            fixed
            class="mb-5"
            style="right: 50px; bottom: 20px"
            v-on="on"
            v-bind="attrs"
            @click="addField()"
          >
            <v-icon>mdi-plus-circle-outline</v-icon>
          </v-btn>
        </template>
        <span>{{ $t("forms.btn.add_field.title") }}</span>
      </v-tooltip>
    </v-fab-transition>
  </div>
</template>

<script>
import FieldIcon from "./field-icon.vue";
import FieldDraggableHandle from "./field-draggable-handle.vue";
import FieldCondition from "./field-condition.vue";
import FieldMenu from "./field-menu.vue";
import FieldType from "./field-type.vue";
import { mapGetters } from "vuex";
import Field from "./field";
export default {
  created() {
    this.setFieldsItems();
  },
  props: {
    schema: {
      type: Array,
      default: function () {
        return [];
      },
    },
    valid: {
      type: Boolean,
      default: true,
    },
  },
  data: () => ({
    duplicateCounter: 0,
    fieldItems: [],
    isDragging: false,
    selected: null,
  }),
  methods: {
    addField() {
      this.builder.push({
        ...this._.cloneDeep(this.fields.text),
        name: this.getFieldName(),
      });

      const index = this.builder.length - 1;

      // set items use in the conditions fields
      this.$watch(`builder.${index}.title`, () => {
        this.setFieldsItems();
      });
      this.$watch(`builder.${index}.name`, () => {
        this.setFieldsItems();
      });
      this.setFieldsItems();

      // scroll to field
      this.scrollToField(index);

      // select field
      this.selectField(index);
    },
    changeField(index, type) {
      const old = { ...this._.cloneDeep(this.builder[index]) };
      const field = { ...this._.cloneDeep(this.fields[type]) };
      this.builder.splice(index, 1, {
        ...field,
        name: old.name,
        title: old.title,
        placeholder: old.placeholder,
        isRequired: old.isRequired,
        isVisible: old.isVisible,
        readOnly: old.readOnly,
      });
    },
    removeField(index) {
      this.builder.splice(index, 1);
      if (index > 0) this.scrollToField(index - 1);
    },
    duplicateField(index) {
      this.duplicateCounter = this.duplicateCounter + 1;
      const field = this._.cloneDeep(this.builder[index]);
      this.builder.splice(index + 1, 0, {
        ...field,
        name: `${field.name}_c${this.duplicateCounter}`,
      });
    },
    selectField(index) {
      if(!this.validate()) {
        this.scrollToField(this.selected);
        return;
      }
      this.selected = index;
    },
    getFieldName() {
      const count = this.builder.length;
      return `question_${count + 1}`;
    },
    scrollToField(index) {
      this.$nextTick(() => {
        const element = this.$refs[`field_${index}`];

        setTimeout(() => {
          window.scrollTo({
            top: element[0].$el.offsetTop,
            behavior: "smooth",
          });
        }, 100);
      });
    },
    validate() {
      return this.$refs.builder.validate();
    },
    setFieldsItems() {
      this.fieldItems = [];
      for (const builder of this.builder) {
        if (builder.title && builder.name);
        this.fieldItems.push({ text: builder.title, value: builder.name });
      }
    },
    updateDraggable(event) {
      if (event.moved) {
        const moved = event.moved;
        if (moved.oldIndex === this.selected) {
          this.selected = moved.newIndex;
        }
      }
    },
  },
  computed: {
    ...mapGetters({ fields: "builder/fields", types: "builder/types" }),
    validValue: {
      get() {
        return this.valid;
      },
      set(value) {
        return this.$emit("update:valid", value);
      },
    },
    builder: {
      get: function () {
        return this.schema;
      },
      set: function (schema) {
        return this.$emit("update:schema", schema);
      },
    },
  },
  components: {
    Field,
    FieldType,
    FieldMenu,
    FieldCondition,
    FieldDraggableHandle,
    FieldIcon,
  },
};
</script>