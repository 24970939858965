<template>
  <v-dialog
    v-model="dialog.display"
    fullscreen
    transition="dialog-bottom-transition"
    persistent
    no-click-animation
  >
    <v-card tile color="#e8eff5">
      <v-app-bar fixed flat color="grey lighten-5" max-height="64">
        <v-btn icon @click="closeDialog()">
          <v-icon>mdi-close</v-icon>
        </v-btn>
        <v-toolbar-title>
          <span class="secondary-font font-weight-medium text-h6">
            {{ $t("forms.progression.title") }}
          </span>
        </v-toolbar-title>
        <v-spacer></v-spacer>
        <v-toolbar-items>
          <v-btn
            @click="save()"
            :disabled="$v.form.$invalid"
            color="primary"
            text
            :loading="loading.save"
            >{{ $t("btn.save") }}</v-btn
          >
        </v-toolbar-items>
      </v-app-bar>
      <v-card-text style="padding-top: 70px">
        <v-row justify="center">
          <v-col md="5" cols="12">
            <v-card>
              <v-card-text>
                <v-text-field
                  :label="$t('forms.progression.fields.title.title')"
                  :placeholder="
                    $t('forms.progression.fields.title.placeholder')
                  "
                  append-icon="mdi-text-short"
                  v-model="form.title"
                  class="font-weight-medium"
                  :error-messages="titleErrors"
                  @input="delayTouch($v.form.title)"
                  @blur="$v.form.title.$touch"
                  maxlength="100"
                ></v-text-field>
                <template v-for="(state, index) in form.states">
                  <div
                    class="d-flex flex-wrap align-center"
                    :key="`flex-${index}`"
                  >
                    <v-text-field
                      :label="$t('forms.progression.fields.status.value.title')"
                      :placeholder="
                        $t('forms.progression.fields.status.value.title')
                      "
                      v-model="form.states[index].value"
                      class="mr-2 font-weight-medium"
                      :error-messages="statesValueErrors(index)"
                      @input="delayTouch($v.form.states.$each[index].value)"
                      @blur="$v.form.states.$each[index].value.$touch"
                      maxlength="50"
                      autocomplete="off"
                    ></v-text-field>
                    <v-text-field
                      :label="$t('forms.progression.fields.status.text.title')"
                      :placeholder="
                        $t('forms.progression.fields.status.text.title')
                      "
                      v-model="form.states[index].text"
                      class="mr-2 font-weight-medium"
                      :error-messages="statesTextErrors(index)"
                      @input="delayTouch($v.form.states.$each[index].text)"
                      @blur="$v.form.states.$each[index].text.$touch"
                      maxlength="100"
                      autocomplete="off"
                    ></v-text-field>
                    <v-text-field
                      class="font-weight-medium mr-2"
                      :label="$t('forms.progression.fields.status.color.title')"
                      :placeolder="
                        $t('forms.progression.fields.status.color.title')
                      "
                      v-model="form.states[index].color"
                      autocomplete="off"
                      v-mask="[
                        /#/,
                        /[0-9A-F]/,
                        /[0-9A-F]/,
                        /[0-9A-F]/,
                        /[0-9A-F]/,
                        /[0-9A-F]/,
                        /[0-9A-F]/,
                      ]"
                      :error-messages="statesColorErrors(index)"
                      @input="delayTouch($v.form.states.$each[index].color)"
                      @blur="$v.form.states.$each[index].color.$touch"
                    >
                      <template v-slot:append>
                        <v-menu
                          v-model="colorPickerMenus[index]"
                          top
                          nudge-bottom="105"
                          nudge-left="16"
                          :close-on-content-click="false"
                        >
                          <template v-slot:activator="{ on }">
                            <div
                              :style="
                                swatchStyle(
                                  colorPickerMenus[index],
                                  form.states[index].color
                                )
                              "
                              v-on="on"
                            />
                          </template>
                          <v-card>
                            <v-card-text class="pa-0">
                              <v-color-picker
                                mode="hexa"
                                v-model="form.states[index].color"
                                flat
                              />
                            </v-card-text>
                          </v-card>
                        </v-menu>
                      </template>
                    </v-text-field>
                    <v-btn @click="removeStatus(index)" icon v-if="index !== 0">
                      <v-icon>mdi-minus-circle-outline</v-icon>
                    </v-btn>
                  </div>
                </template>
              </v-card-text>
              <v-card-actions class="mt-0 pt-0">
                <v-spacer></v-spacer>
                <v-btn @click="addState()" depressed>{{ $t("btn.add") }}</v-btn>
              </v-card-actions>
            </v-card>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapActions } from "vuex";
import {
  required,
  helpers,
  maxLength,
  minLength,
} from "vuelidate/lib/validators";
import FormDelayTouchMixin from "../../mixins/form-delay-touch";

import { defaultFormsProgressState } from './../../constants';

export default {
  mixins: [FormDelayTouchMixin],
  props: {
    dialog: {
      type: Object,
      default: function () {
        return {
          display: false,
        };
      },
    },
    formsId: {
      type: Number,
    },
    progression: {
      type: Object,
      default: function () {
        return {};
      },
    },
  },
  data: () => ({
    colorPickerMenus: [],
    loading: {
      save: false,
    },
  }),
  validations() {
    const validations = {
      form: {
        title: {
          required: required,
          maxLength: maxLength(100),
        },
        states: {
          required,
          minLength: minLength(1),
          $each: {
            value: { required, maxLength: maxLength(50) },
            text: { required, maxLength: maxLength(100) },
            color: {
              required,
              matchColor: (val) =>
                !helpers.req(val) || /#[A-F0-9]{6}/.test(val),
            },
          },
        },
      },
    };
    return validations;
  },
  methods: {
    ...mapActions({ request: "request", notify: "notification/notify" }),
    addState() {
      this.form.states.push({ ...defaultFormsProgressState });
    },
    removeStatus(index) {
      if (this.form.states.length > 1) {
        this.form.states.splice(index, 1);
      }
    },
    async save() {
      this.notify({ status: false });

      this.$v.form.$touch();
      if (this.$v.form.$invalid) {
        this.notify({ message: this.$t("form_error") });
        return;
      }

      this.loading.save = true;
      try {
        const response = await this.request({
          url: "forms.addStatuses",
          method: "POST",
          data: {
            formsId: this.formsId,
            title: this.form.title,
            states: this.form.states,
          },
          messages: {
            200: this.$t("forms.progression.success"),
            403: true,
            404: this.$t("forms.errors.404"),
            422: (errors) => {
              if (errors.code === "E101") {
                return this.$t("forms.errors.E101");
              } else {
                return this.$t("error_occured");
              }
            },
            500: true,
          },
        });
        this.$emit("update:progression", response.data);
      } catch (error) {
        // empty
      }
      this.loading.save = false;
    },
    swatchStyle(menu, color) {
      return this.$utils.swatchStyle(menu, color);
    },
    statesValueErrors(index) {
      const errors = [];
      if (!this.$v.form.states.$each[index].value.$dirty) return errors;

      !this.$v.form.states.$each[index].value.required &&
        errors.push(this.$t("forms.progression.fields.status.value.required"));
      !this.$v.form.states.$each[index].value.maxLength &&
        errors.push(
          this.$t("forms.progression.fields.status.value.maxLength", {
            max: this.$v.form.states.$each[index].value.$params.maxLength.max,
          })
        );
      return errors;
    },
    statesTextErrors(index) {
      const errors = [];
      if (!this.$v.form.states.$each[index].text.$dirty) return errors;

      !this.$v.form.states.$each[index].text.required &&
        errors.push(this.$t("forms.progression.fields.status.text.required"));
      !this.$v.form.states.$each[index].text.maxLength &&
        errors.push(
          this.$t("forms.progression.fields.status.text.maxLength", {
            max: this.$v.form.states.$each[index].text.$params.maxLength.max,
          })
        );
      return errors;
    },
    statesColorErrors(index) {
      const errors = [];
      if (!this.$v.form.states.$each[index].color.$dirty) return errors;

      !this.$v.form.states.$each[index].color.required &&
        errors.push(this.$t("forms.progression.fields.status.color.required"));
      !this.$v.form.states.$each[index].color.matchColor &&
        errors.push(this.$t("forms.progression.fields.status.color.invalid"));
      return errors;
    },
    closeDialog() {
      this.dialog.display = false;
    },
  },
  computed: {
    titleErrors() {
      const errors = [];
      if (!this.$v.form.title.$dirty) return errors;

      !this.$v.form.title.required &&
        errors.push(this.$t("forms.progression.fields.title.required"));
      !this.$v.form.title.maxLength &&
        errors.push(
          this.$t("forms.progression.fields.title.maxLength", {
            max: this.$v.form.title.params.maxLength.max,
          })
        );

      return errors;
    },
    form: {
      set(value) {
        this.$emit("update:progression", value);
      },
      get() {
        return this.progression
      },
    },
  },
};
</script>