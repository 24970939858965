<template>
  <div>
    <v-card class="rounded-lg mb-4">
      <div class="d-flex align-center justify-start px-4 py-3 flex-wrap">
        <v-tooltip top>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              @click="manageStatuses()"
              v-if="$permission.$can('form.manage-status', ['user', 'service'])"
              depressed
              class="mr-2"
              v-on="on"
              v-bind="attrs"
              :disabled="!isEdit"
            >
              <v-icon left>mdi-list-status</v-icon>
              {{ $t("forms.btn.manage_statuses.title") }}
            </v-btn>
          </template>
          <span>{{ $t("forms.btn.manage_statuses.description") }}</span>
        </v-tooltip>
        <v-tooltip top>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              @click="manageEvents()"
              v-if="$permission.$can('form.manage-event', ['user', 'service'])"
              depressed
              class="mr-2"
              v-on="on"
              v-bind="attrs"
              :disabled="!isEdit"
            >
              <v-icon left>mdi-calendar-blank-outline</v-icon>
              {{ $t("forms.btn.manage_events.title") }}
            </v-btn>
          </template>
          <span>{{ $t("forms.btn.manage_events.description") }}</span>
        </v-tooltip>
        <v-tooltip top>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              @click="manageSla()"
              v-on="on"
              v-bind="attrs"
              depressed
              class="mr-2"
              v-if="$permission.$can('form.manage-sla', ['user', 'service'])"
              :disabled="!isEdit"
            >
              <v-icon left>mdi-handshake-outline</v-icon>
              {{ $t("forms.btn.manage_sla.title") }}
            </v-btn>
          </template>
          <span>{{ $t("forms.btn.manage_sla.description") }}</span>
        </v-tooltip>
        <div class="ml-auto">
          <v-btn :disabled="!formIsValid" color="primary" @click="save()">{{
            $t("btn.save")
          }}</v-btn>
        </div>
      </div>
    </v-card>
    <v-card max-width="700" class="rounded-lg ma-auto">
      <v-card-text>
        <v-text-field
          :label="$t('forms.fields.name.title')"
          class="text-subtitle-1"
          v-model="form.name"
          :placeholder="$t('forms.fields.name.placeholder2')"
          append-icon="mdi-text-short"
        ></v-text-field>
        <v-select
          :items="items.services"
          :loading="loading.service"
          item-text="name"
          item-value="id"
          :placeholder="$i18n.t('forms.fields.serviceId.placeholder')"
          :label="$i18n.t('forms.fields.serviceId.title')"
          append-icon="mdi-domain"
          v-model="form.serviceId"
        ></v-select>
      </v-card-text>
    </v-card>
    <div style="max-width: 700px" class="rounded-lg ma-auto">
      <forms-builder
        ref="builder"
        :schema.sync="builder"
        :valid.sync="valid"
      ></forms-builder>
      <v-card class="mt-5" elevation="0" color="rgba(255,255,255,0)">
        <v-card-actions class="px-0">
          <v-spacer></v-spacer>
          <v-btn
            :disabled="!formIsValid"
            :loading="loading.save"
            color="primary"
            @click="save()"
            >{{ formSubmitTitle }}</v-btn
          >
        </v-card-actions>
      </v-card>
    </div>
    <!--forms options -->
    <forms-progression
      v-if="form.name"
      :forms-id="form.id"
      :dialog="dialog.progression"
      :progression.sync="progressionValue"
      @statusesUpdated="statusesUpdated"
    ></forms-progression>
    <forms-events
      :events="events"
      @eventsUpdated="eventsUpdated"
      @eventDeleted="eventDeleted"
      :forms-id="form.id"
      :dialog="dialog.events"
      :forms-fields="items.fields"
    ></forms-events>
    <forms-sla
      :forms-id="form.id"
      :items="items.sla"
      :sla.sync="slaValue"
      :dialog="dialog.sla"
      :service-id="form.serviceId"
    ></forms-sla>
  </div>
</template>

<script>
import { required, maxLength } from "vuelidate/lib/validators";
import FormsBuilder from "./builder/index.vue";
import { mapActions } from "vuex";
import FormsProgression from "./progression";
import FormsEvents from "./events";
import FormsSla from "./sla";

export default {
  props: {
    form: {
      type: Object,
      default: function() {
        return {}
      }
    },
    schema: {
      type: Array,
      default: function () {
        return [];
      },
    },
    sla: {
      type: Object,
      default: function () {
        return {};
      },
    },
    events: {
      type: Array,
      default: function () {
        return [];
      },
    },
    progression: {
      type: Object,
      default: function () {
        return {};
      },
    },
  },
  created() {
    this.getServiceItems();
  },
  data: () => ({
    valid: true,
    loading: {
      service: false,
      save: false,
    },
    items: {
      services: [],
      sla: [],
      fields: [],
    },
    dialog: {
      progression: {
        display: false,
      },
      events: {
        display: false
      },
      sla: {
        display: false
      }
    },
  }),
  validations: {
    form: {
      id: {
        type: String | Number,
      },
      name: {
        required,
        maxLength: maxLength(100),
      },
      serviceId: {
        required,
      },
    },
  },
  methods: {
    ...mapActions({ request: "request", notify: "notification/notify" }),
    async save() {
      this.$refs.builder.validate();
      if (!this.valid) {
        this.notify({ message: this.$t("error_codes.400") });
        return;
      }
      const nonUniqueNamesIndex = this.nonUniqueNameIndex(this.schema);

      if (nonUniqueNamesIndex.length) {
        this.notify({ message: this.$t("forms.errors.non_unique_name") });

        // select and scroll to field
        this.$refs.builder.selectField(nonUniqueNamesIndex[0]);
        this.$refs.builder.scrollToField(nonUniqueNamesIndex[0]);
        return;
      }

      let url;
      let formData = {
        serviceId: this.form.serviceId,
        name: this.form.name,
        schema: this.schema,
      };
      if (this.form.id) {
        url = "forms.update";
        formData.formsId = this.form.id;
      } else {
        url = "forms.create";
      }

      this.loading.save = true;
      try {
        const response = await this.request({
          url: url,
          method: "POST",
          data: formData,
          messages: {
            201: this.$t("forms.add.success"),
            200: this.$t("forms.edit.success"),
            400: true,
            422: (error) => {
              if (error.code === "E100") {
                return this.$t("forms.errors.E100");
              } else if (error.code === "E101") {
                return this.$t("error_codes.400");
              } else {
                return this.$t("error_codes.500");
              }
            },
            403: true,
          },
        });
        const data = response.data;
        // redirect to route edit form
        if (!this.form.id) {
          this.$router.push({ name: "forms-edit", params: { id: data.id } });
        }
      } catch (error) {
        // empty
      }
      this.loading.save = false;
    },
    nonUniqueNameIndex(schema) {
      const nonUniqueNamesIndex = [];
      schema.filter((field, k) => {
        const nonUnique = schema.some((item, l) => {
          return item.name === field.name && l !== k;
        });
        if (nonUnique) {
          nonUniqueNamesIndex.push(k);
        }
      });
      return nonUniqueNamesIndex;
    },
    async getServiceItems() {
      this.loading.service = true;
      this.items.services = [];
      this.items.services = (
        await this.request({ url: "services.list" })
      ).data.data;
      if (this.items.services.length && !this.form.id) {
        this.form.serviceId = this.items.services[0].id;
      }
      this.loading.service = false;
    },
    manageStatuses() {
      this.dialog.progression.display = true;
    },
    manageEvents() {
      this.dialog.events.display = true;
    },
    manageSla() {
      this.dialog.sla.display = true;
    },
    statusesUpdated(statuses) {
      this.$set(this, "statuses", statuses);
    },
    eventsUpdated(events) {
      this.$emit('update:events', events.data);
    },
    eventDeleted(eventId) {
      const findIndex = this.events.findIndex((event) => event._id === eventId);
      if (findIndex > -1) {
        this.events.splice(findIndex, 1);
      }
    },
  },
  computed: {
    formIsValid() {
      return this.valid && !this.$v.form.$invalid;
    },
    nameErrors() {
      const errors = [];

      if (!this.$v.form.name.$dirty) return errors;

      !this.$v.form.name.required &&
        errors.push(this.$t("forms.fields.name.required"));
      !this.$v.form.name.maxLength &&
        errors.push(
          this.$t("forms.fields.name.max_length", {
            max: this.$v.form.name.$params.maxLength.max,
          })
        );

      return errors;
    },
    serviceIdErrors() {
      const errors = [];

      if (!this.$v.form.serviceId.$dirty) return errors;

      !this.$v.form.serviceId.required &&
        errors.push(this.$t("common.fields.serviceId.required"));

      return errors;
    },
    builder: {
      get: function () {
        return this.schema;
      },
      set: function (val) {
        const schema = val.map((v) => {
          // eslint-disable-next-line no-unused-vars
          const { error, ...rest } = v;
          return rest;
        });
        return this.$emit("update:schema", schema);
      },
    },
    progressionValue: {
      set(value) {
        this.$emit('update:progress', value);
      },
      get() {
        return this.progression;
      }
    },
    slaValue: {
      set(value) {
        this.$emit('update:sla', value);
      },
      get() {
        return this.sla;
      }
    },
    formSubmitTitle() {
      if (!this.form.id) {
        return this.$t("btn.save");
      } else {
        return this.$t("btn.update");
      }
    },
    isEdit() {
      return this.form.id ? true : false;
    },
  },
  components: {
    FormsBuilder,
    FormsProgression,
    FormsEvents,
    FormsSla
  },
};
</script>