<template>
  <div>
    <div>
      <span class="text-subtitle-1 font-weight-medium">{{
        $t("forms.builder.validators.title")
      }}</span>
      <v-tooltip top>
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            @click="addValidator()"
            class="ml-2"
            v-on="on"
            v-bind="attrs"
            icon
            ><v-icon>mdi-plus-circle-outline</v-icon></v-btn
          >
        </template>
        <span>{{ $t("forms.btn.add_validator.title") }}</span>
      </v-tooltip>
    </div>
    <div
      class="d-flex wrap align-center mb-3"
      v-for="(validator, index) in validators"
      :key="index"
    >
      <v-select
        dense
        :items="validatorsTypes"
        v-model="validator.type"
        :item-text="(item) => $t(item.text)"
        style="max-width: 165px"
        @change="changeValidator(index, validator.type)"
      ></v-select>
      <template v-if="validator.type === 'text'">
        <v-text-field
          v-model="validator.minLength"
          :placeholder="$t('common.fields.text.min_length')"
          dense
          class="ml-3"
          style="max-width: 150px"
          :rules="rulesLength"
        ></v-text-field>
        <v-text-field
          v-model="validator.maxLength"
          :placeholder="$t('common.fields.text.max_length')"
          dense
          class="ml-3"
          style="max-width: 150px"
          :rules="rulesLength"
        ></v-text-field>
      </template>
      <template v-if="validator.type === 'regex'">
        <v-text-field
          v-model="validator.regex"
          :placeholder="$t('common.fields.regex.title')"
          dense
          class="ml-3"
          style="max-width: 100px"
          :rules="rulesRegex"
        ></v-text-field>
      </template>
      <v-text-field
        dense
        class="ml-3"
        :placeholder="$t('common.fields.error_message.title')"
      ></v-text-field>
      <v-tooltip top>
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            small
            icon
            class="ml-2"
            v-on="on"
            v-bind="attrs"
            @click="removeValidator(index)"
          >
            <v-icon small>mdi-close</v-icon>
          </v-btn>
        </template>
        <span>{{ $t("btn.delete") }}</span>
      </v-tooltip>
    </div>
  </div>
</template>

<script>
import DefaultRulesMixin from "../mixins/default-rules";
export default {
  mixins: [DefaultRulesMixin],
  props: {
    validators: {
      type: Array,
      default: function () {
        return [];
      },
    },
  },
  data: () => ({
    validatorsTypes: [
      { text: "common.fields.text.length", value: "text" },
      { text: "common.fields.regex.title", value: "regex" },
    ],
    validatorsOptions: {
      text: { type: "text", text: null, minLength: 1, maxLength: 255 },
      regex: { type: "regex", regex: "/^[0-9]+$", text: null },
    },
  }),
  methods: {
    addValidator() {
      this.validators.push({ ...this.validatorsOptions.text });
    },
    changeValidator(index, type) {
      this.validators.splice(index, 1, { ...this.validatorsOptions[type] });
    },
    removeValidator(index) {
      this.validators.splice(index, 1);
    },
  },
};
</script>