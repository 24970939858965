<template>
  <div>
    <v-select
      outlined
      :items="items"
      :item-text="(item) => $t(item.text)"
      v-model="typeValue"
      dense
      hide-details
      ><template v-slot:selection="{ item }">
        <div class="v-select__selection v-select__selection--comma">
          <v-icon left v-if="icons[item.value]">{{ icons[item.value] }}</v-icon
          >{{ $t(item.text) }}
        </div> </template
      ><template v-slot:item="{ item, on, attrs }">
        <v-list-item v-on="on" v-bind="attrs">
          <v-list-item-icon v-if="icons[item.value]">
            <v-icon>{{ icons[item.value]  }}</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>{{ $t(item.text) }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </template></v-select
    >
  </div>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  data: () => ({}),
  props: {
    type: {
      type: String,
    },
  },
  computed: {
    ...mapGetters({ items: "builder/types", icons: "builder/iconTypes" }),
    typeValue: {
      get: function () {
        return this.type;
      },
      set: function (value) {
        return this.$emit("changed", value);
      },
    },
  },
};
</script>