<template>
  <div class="mt-5 ma-auto">
    <div v-if="!selected">
      <slot name="field-info"></slot>
    </div>
    <v-card v-else class="rounded-lg field-selected elevation-4">
      <slot name="draggable-handler"></slot>
      <v-card-text class="pt-0">
        <v-row wrap>
          <v-col cols="12" sm="6">
            <v-text-field
              v-model="options.name"
              dense
              filled
              :rules="rulesName"
            ></v-text-field>
          </v-col>
          <v-col cols="12" sm="6">
            <slot name="types"></slot>
          </v-col>
        </v-row>
        <v-text-field
          dense
          :placeholder="$t('forms.builder.fields.model.hash.title')"
          v-model="options.hash"
          class="mb-4"
          :rules="rulesHash"
          ref="hash"
        ></v-text-field>
        <v-text-field
          dense
          :placeholder="$t('common.fields.title.title')"
          v-model="options.title"
          class="mb-4"
          :rules="rulesTitle"
          ref="title"
        ></v-text-field>
        <v-text-field
          dense
          :placeholder="$t('common.fields.description.title')"
          v-model="options.placeholder"
          :rules="rulesDescription"
        ></v-text-field>
      </v-card-text>
      <v-card-text class="pt-0">
        <div class="mb-3">
          <span class="text-subtitle-1 font-weight-medium">{{
            $t("forms.builder.fields.model.parent.title")
          }}</span>
          <v-tooltip top>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                @click="addParent()"
                class="ml-2"
                v-on="on"
                v-bind="attrs"
                icon
                ><v-icon>mdi-plus-circle-outline</v-icon></v-btn
              >
            </template>
            <span>{{ $t("forms.builder.fields.model.parent.title") }}</span>
          </v-tooltip>
        </div>
        <div
          class="d-flex wrap align-center mb-3"
          v-for="(item, index) in options.parents"
          :key="index"
        >
          <v-text-field
            :label="$t('forms.builder.fields.model.hash.title')"
            v-model="item.hash"
            dense
            :rules="rulesHash"
          ></v-text-field>
          <v-select
            :label="$t('forms.builder.fields.model.parent.field.title')"
            v-model="item.field"
            class="ml-4"
            dense
            :rules="rulesField"
            :items="fieldItems.filter(f => f.value !== options.name)"
          ></v-select>
          <v-tooltip top>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                v-on="on"
                v-bind="attrs"
                small
                icon
                class="ml-2"
                @click="removeParent(index)"
              >
                <v-icon small>mdi-close</v-icon>
              </v-btn>
            </template>
            <span>{{ $t("btn.delete") }}</span>
          </v-tooltip>
        </div>
      </v-card-text>
      <v-card-text class="pt-0">
        <slot name="conditions"></slot>
      </v-card-text>
      <v-divider></v-divider>
      <v-card-actions class="px-4 py-0">
        <v-spacer></v-spacer>
        <slot name="footer-controls"></slot>
      </v-card-actions>
    </v-card>
  </div>
</template>

<script>
import DefaultRulesMixin from "../mixins/default-rules";

export default {
  mixins: [DefaultRulesMixin],
  data: () => ({}),
  props: {
    options: {
      type: Object,
      default: function () {
        return {};
      },
    },
    selected: {
      type: Boolean,
      default: false,
    },
    fieldItems: {
      type: Array,
      default: function() {
        return []
      }
    }
  },
  methods: {
    addParent() {
      this.options.parents.push({
        hash: null,
        field: null,
      });
    },
    removeParent(index) {
      this.options.parents.splice(index, 1);
    },
  },
  watch: {
    selected: function (val) {
      if (val) {
        this.$nextTick(() => {
          this.$refs.hash.$refs.input.focus();
        });
      }
    },
  },
  computed: {
    rulesHash() {
      return [
        (value) => {
          if (value == null || value === "")
            return this.$t("forms.builder.fields.model.hash.required");
          if (!/^[a-z0-9]{1,50}$/.test(value))
            return this.$t("forms.builder.fields.model.hash.invalid");
          return true;
        },
      ];
    },
    rulesField() {
      return [
        (value) => {
          if (value == null || value === "")
            return this.$t("forms.builder.fields.model.parent.field.required");
          return true;
        },
      ]
    }
  },
  components: {},
};
</script>