<template>
  <div
    class="d-flex flex-wrap"
    v-if="formsFieldNames.ticket || formsFieldNames.form"
  >
    <template v-if="formsFieldNames.ticket">
      <template v-for="(vTicketField, ticketField) in formsFieldNames.ticket">
        <v-tooltip top :key="ticketField">
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              outlined
              small
              v-bind="attrs"
              class="mr-2 mb-1 font-weight-medium px-2"
              v-on="on"
              @click="insertField(ticketField)"
              color="grey darken-2"
              >{{ $t(`forms.fields.ticket.${ticketField}`) }}</v-btn
            >
          </template>
          <span>{{ $t("btn.insert_field") }}</span>
        </v-tooltip>
      </template>
    </template>
    <template v-if="formsFieldNames.form">
      <template v-for="(vFormField, formField) in formsFieldNames.form">
        <v-tooltip top :key="vFormField">
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              outlined
              small
              v-bind="attrs"
              class="mr-2 font-weight-medium px-2"
              v-on="on"
              @click="insertField(formField)"
              color="grey darken-2"
              >{{ vFormField }}</v-btn
            >
          </template>
          <span>{{ $t("btn.insert_field") }}</span>
        </v-tooltip>
      </template>
    </template>
  </div>
</template>

<script>
export default {
  props: {
    formsFieldNames: {
      type: Object,
      default: function () {
        return {};
      },
    },
  },
  data: () => ({}),
  methods: {
    insertField(insertText) {
      this.$emit("insertField", insertText);
    },
  },
};
</script>