export default {
  computed: {
    rulesTitle() {
      return [
        (value) => {
          if (value == null || value === '') return this.$t("common.fields.title.required");
          if (value.length > 255)
            return this.$t("common.fields.title.maxLength", { max: 255 });
          return true;
        },
      ];
    },
    rulesName() {
      return [
        (value) => {
          if (value == null || value === '') return this.$t("common.fields.identifier.required");
          if (!/^[a-z0-9_]+$/i.test(value))
            return this.$t("common.fields.identifier.invalid");
          if (value.length > 30)
            return this.$t("common.fields.identifier.max_length", { max: 30 });
          return true;
        },
      ];
    },
    rulesLength() {
      return [
        (value) => {
          if (value == null || value === '') return this.$t("common.fields.text.length_required");
          if (!/^[0-9]+$/.test(value))
            return this.$t("common.fields.text.invalid_length");
          if (value < 1) return this.$t("common.fields.text.length_min");
          if (value > 255) return this.$t("common.fields.text.length_max");
          return true;
        },
      ];
    },
    rulesRegex() {
      return [
        (value) => {
          if (value == null || value === '') return this.$t("common.fields.regex.required");
          return true;
        },
      ];
    },
    rulesNumberValue() {
      return [
        (value) => {
          if (value == null || value === '') return this.$t("common.fields.number.required");
          if (!/^[0-9]+$/.test(value))
            return this.$t("common.fields.number.invalid");
          return true;
        },
      ];
    },
    rulesDescription() {
      return [
        (value) => {
          if (value == null || value === '') return true;
          if (value.length > 1600)
            return this.$t("common.fields.text.max_length_error", {
              max: 1600,
            });
          return true;
        },
      ];
    },
  },
};
