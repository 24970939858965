<template>
  <div class="mt-5 ma-auto">
    <div v-if="!selected">
      <slot name="field-info"></slot>
    </div>
    <v-card v-else class="rounded-lg field-selected elevation-4">
      <slot name="draggable-handler"></slot>
      <v-card-text class="pt-0">
        <v-row wrap>
          <v-col cols="12" sm="6">
            <v-text-field
              v-model="options.name"
              dense
              filled
              :rules="rulesName"
            ></v-text-field>
          </v-col>
          <v-col cols="12" sm="6">
            <slot name="types"></slot>
          </v-col>
        </v-row>
        <v-text-field
          dense
          :placeholder="$t('common.fields.title.title')"
          v-model="options.title"
          class="mb-4"
          :rules="rulesTitle"
          ref="title"
        ></v-text-field>
        <v-text-field
          dense
          :placeholder="$t('common.fields.description.title')"
          v-model="options.placeholder"
          :rules="rulesDescription"
        ></v-text-field>
      </v-card-text>
      <v-card-text>
        <text-validator
          class="mb-5"
          :validators="options.validators"
        ></text-validator>
        <slot name="conditions"></slot>
      </v-card-text>
      <v-divider></v-divider>
      <v-card-actions class="px-4 py-0">
        <v-spacer></v-spacer>
        <slot name="footer-controls"></slot>
      </v-card-actions>
    </v-card>
  </div>
</template>

<script>
import DefaultRulesMixin from "../mixins/default-rules";
import TextValidator from "../validators/text-validator.vue";

export default {
  mixins: [DefaultRulesMixin],
  data: () => ({}),
  props: {
    options: {
      type: Object,
      default: function () {
        return {};
      },
    },
    selected: {
      type: Boolean,
      default: false,
    },
  },
  methods: {},
  watch: {
    selected: function (val) {
      if (val) {
        this.$nextTick(() => {
          this.$refs.title.$refs.input.focus();
        });
      }
    },
  },
  components: {
    TextValidator,
  },
};
</script>