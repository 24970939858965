<template>
  <div>
    <div class="d-flex justify-center align-center draggable-handle mb-0">
      <v-icon
        color="grey darken-1"
        size="20"
        v-show="hover ? true : false"
        class="my-0 py-0"
        >mdi-drag-horizontal</v-icon
      >
    </div>
  </div>
</template>

<script>
  export default {
    props: {
      hover: {
        type: Boolean,
        default: false
      }
    }
  }
</script>