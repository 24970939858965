<template>
  <component
    :is="`${options.type}-field`"
    :selected="selected"
    :options="options"
    :field-items="fieldItems"
  >
    <template v-slot:field-info>
      <slot name="field-info"></slot>
    </template>
    <template v-slot:draggable-handler>
      <slot name="draggable-handler"></slot>
    </template>
    <template v-slot:types>
      <slot name="types"></slot>
    </template>
    <template v-slot:footer-controls>
      <slot name="footer-controls"></slot>
    </template>
    <template v-slot:conditions>
      <slot name="conditions"></slot>
    </template>
  </component>
</template>

<script>
import ModelField from "./fields/model";
import TextField from "./fields/text";
import TextareaField from "./fields/textarea";
import SelectField from "./fields/select";
import DatetimeField from "./fields/datetime";


export default {
  props: {
    options: {
      type: Object,
      default: function () {
        return {};
      },
    },
    selected: {
      type: Boolean,
      default: false,
    },
    fieldItems: {
      type: Array,
      default: function() {
        return []
      }
    }
  },
  data: () => ({}),
  components: {
    TextField,
    TextareaField,
    SelectField,
    DatetimeField,
    ModelField
  },
};
</script>