<template>
  <div>
    <div class="mb-4">
      <span class="text-subtitle-1 font-weight-medium">{{
        $t("forms.builder.conditions.title")
      }}</span>
      <v-tooltip top>
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            @click="addCondition()"
            class="ml-2"
            v-on="on"
            v-bind="attrs"
            icon
            ><v-icon>mdi-plus-circle-outline</v-icon></v-btn
          >
        </template>
        <span>{{ $t("forms.btn.add_display_condition.title") }}</span>
      </v-tooltip>
    </div>
    <template>
      <div v-for="(condition, index) in conditions" :key="index">
        <div class="d-flex wrap align-center mb-3">
          <v-select
            dense
            :items="fieldItems"
            v-model="condition.field"
            :label="$t('forms.builder.conditions.fields.field.title')"
            :rules="ruleField"
          ></v-select>
          <v-select
            dense
            :items="operatorItems"
            v-model="condition.operator"
            :label="$t('common.fields.operator.title')"
            :item-text="(item) => $t(item.text)"
            class="ml-4"
            :rules="ruleOperator"
          ></v-select>
          <v-text-field
            class="ml-4"
            dense
            :label="$t('common.fields.value.title')"
            v-model="condition.value"
            :rules="ruleValue"
          ></v-text-field>
          <v-select
            dense
            :items="logicItems"
            v-model="condition.logic"
            :label="$t('common.fields.logic.title')"
            :item-text="(item) => $t(item.text)"
            style="max-width: 100px"
            class="ml-4"
            v-if="conditions.length !== index + 1"
            :rules="ruleLogic"
          ></v-select>
          <v-btn small icon class="ml-2" @click="removeCondition(index)">
            <v-icon small>mdi-close</v-icon>
          </v-btn>
        </div>
      </div>
    </template>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  props: {
    fieldItems: {
      type: Array,
      default: function () {
        return [];
      },
    },
    conditions: {
      type: Array,
      default: function () {
        return [];
      },
    },
  },
  data: () => ({}),
  methods: {
    addCondition() {
      this.conditions.push({
        operator: "$eq",
        field: this.fieldItems[0].value,
        value: 0,
        logic: "AND",
      });
    },
    removeCondition(index) {
      this.conditions.splice(index, 1);
    },
  },
  computed: {
    ...mapGetters({
      operatorItems: "builder/conditionOperators",
      logicItems: "builder/conditionLogics",
    }),
    ruleField() {
      return [
        (value) => {
          if (value == null || value === '')
            return this.$t("forms.builder.conditions.fields.field.required");
          return true;
        },
      ];
    },
    ruleOperator() {
      return [
        (value) => {
          if (value == null || value === '') return this.$t("common.fields.operator.required");
          return true;
        },
      ];
    },
    ruleValue() {
      return [
        (value) => {
          if (value == null || value === '') return this.$t("common.fields.value.required");
          if (value.length > 255)
            return this.$t("common.fields.value.max_length", { max: 255 });
          return true;
        },
      ];
    },
    ruleLogic() {
      return [
        (value) => {
          if (value == null || value === '') return this.$t("common.fields.logic.required");
          return true;
        },
      ];
    },
  },
};
</script>