<template>
  <v-icon :color="color">{{ iconTypes[type] }}</v-icon>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  props: {
    type: {
      type: String
    },
    color: {
      type: String,
      default: "grey darken-2"
    }
  },
  data: () => ({
  }),
  computed: {
    ...mapGetters({ iconTypes: "builder/iconTypes" })
  }
}
</script>