<template>
  <v-dialog v-model="dialog.display" scrollable max-width="500">
    <v-card>
      <v-card-title class="text-subtitle-1">
        <span class="font-weight-bold">{{ $t("forms.sla.title") }}</span>
        <v-btn class="ml-auto" icon @click="dialog.display = false">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-card-title>
      <v-card-text class="mt-4">
        <v-select
          :items="items"
          return-object
          :label="$t('forms.sla.fields.sla.title')"
          :placeholder="$t('forms.sla.fields.sla.placeholder')"
          v-model="slaValue"
          item-text="title"
          item-value="identifier"
          clearable
        ></v-select>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn @click="save()" :loading="loading" text color="secondary">{{
          $t("btn.save")
        }}</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapActions } from "vuex";
export default {
  props: {
    dialog: {
      type: Object,
      default: function () {
        return {
          display: false,
        };
      },
    },
    sla: {
      type: Object,
      default: function () {
        return {};
      },
    },
    formsId: {
      type: Number,
    },
    serviceId: {
      type: Number,
    },
  },
  data: () => ({
    loading: false,
    items: [],
  }),
  methods: {
    ...mapActions({ request: "request" }),
    async save() {
      this.loading = true;
      try {
        const slaId = this.sla.id;
        let url = "forms.removeSla";
        let data = { formsId: this.formsId };

        if (slaId) {
          data.slaId = slaId;
          url = "forms.addSla";
        }

        await this.request({
          url: url,
          data: data,
          method: "POST",
          messages: {
            403: true,
            400: true,
            500: true,
            200: this.$t("forms.sla.add.success"),
            204: this.$t("forms.sla.delete.success"),
            422: (error) => {
              if (error.code === "E101") {
                return this.$t("form_error");
              }
              return this.$t("error_occured");
            },
          },
        });
        this.dialog.display = false;
      } catch (error) {
        // empty
      }
      this.loading = false;
    },
  },
  computed: {
    slaValue: {
      get: function () {
        return this.sla;
      },
      set: function (sla) {
        this.$emit("update:sla", sla);
      },
    },
  },
  watch: {
    async "dialog.display"(val) {
      if (!val) return;
      this.items = [];
      if (!this.serviceId) return;
      try {
        const response = await this.request({
          url: `sla.list?filter=service.id||$eq||${this.serviceId}`,
        });
        for (const sla of response.data.data) {
          this.items.push({
            id: sla.id,
            title: sla.data.title,
            identifier: sla.identifier,
          });
        }
      } catch (error) {
        // empty
      }
    },
  },
};
</script>